










































































import { Component, Vue } from 'vue-property-decorator';
import { required, minLength, sameAs } from 'vuelidate/lib/validators';
import { passwordRegex } from '@shared/services/validators';
import GoogleLogin from 'vue-google-login';
import ShowContent from '@shared/components/ShowContent.vue';
Component.registerHooks(['validations']);

@Component({
  components: {
		GoogleLogin,
		ShowContent
  }
})
export default class CreateNewPassword extends Vue {
  $v: any;
  requestFailed = false;
  requestSuccess = false;
  showOverlay = false;
  email = '';
  token = '';
  form = {
    password: null,
    matchPass: null
  }

  created() {
    if(!this.$route.query.Email || !this.$route.query.Token) {
      this.$router.push('/');
      return;
    }
    this.email = this.$route.query.Email as string;
    this.token = this.$route.query.Token as string;
  }

  validations() {
    return {
      form: {
        password: {
          required,
          passwordRegex,
          minLength: minLength(6),
        },
        matchPass: {
          required,
          matchPasswords: sameAs('password')
        },
      }
    };
  }

  validateState(name: string|number) {
    const { $dirty, $error } = this.$v.form[name] || {};
    return $dirty ? !$error : null;
  }

  resetForm() {
    this.form = {
      password: null,
      matchPass: null
    };

    this.$nextTick(() => {
      this.$v.$reset();
    });
  }

  onSubmit() {
    this.$v.form.$touch();
    if (this.$v.form.$anyError) {
      return;
    }
    this.requestFailed = false;
    this.requestSuccess = false;
    this.showOverlay = true;
    this.$store.dispatch('auth/createPassword', { email: this.email, password: this.form.password, token: this.token})
      .then(() =>  {
        this.resetForm();
        this.requestSuccess = true;
        this.showOverlay = false;
      },
      () => {
        this.requestFailed = true;
        this.showOverlay = false;
      });
  }

}
